import React from 'react';
import vg from "../assest/2.webp";
import { AiFillGoogleCircle, AiFillAmazonCircle, AiFillInstagram, AiFillYoutube } from "react-icons/ai"
import Lottie from 'lottie-react';
import Ghost from '../assest/ghost.json';
import Mobile from '../assest/mobile animation.json';
import Website from '../assest/html development.json';






import webanimation from '../assest/websiteanimation.json';





const Home = () => {

    return (
        <>
            <div className="home" id="home">
                <main>
                    <p>welcome to</p>

                    <h1>YouthIT</h1>
                    <p>Empowering the Future</p>
                    <p>through Technology</p>

                </main>
                <div className="animation">
                    <Lottie animationData={webanimation} />

                </div>
            </div>
            <div className="about-us">
                <div className="home3" id='about'>
                    <div>
                        <h1>About us?</h1>
                        <p>Founded with a vision to revolutionize the IT industry, Youth IT brings together a dynamic team of experts with a shared passion for innovation. With years of collective experience, we've built a reputation for excellence, delivering cutting-edge solutions to clients across various industries.</p>
                    </div>
                </div>

            </div>
            <div className="home2">
                <img src={vg} alt="Grapgics" />
                <div>
                    <p>
                        At Youth IT, we believe in harnessing the power of technology to shape a better tomorrow.
                        Our team of passionate and skilled professionals is dedicated to providing innovative
                        IT solutions that drive business success. Explore our services and discover
                        how we can help you achieve your goals.</p>
                </div>
            </div>
            <div className="our-mission">
                <div>
                    <h1>Our mission</h1>
                    <p>To empower businesses with the latest technology solutions, enabling them to thrive in an ever-evolving digital landscape. We strive to foster creativity, collaboration, and growth in every project we undertake.</p>
                </div>
            </div>
            <div>
                <h1>Services we can provide</h1>
            </div>
            <div className="card-collection">

                <div className="cards">
                    <div className="card-animation">
                        <Lottie animationData={Website} />
                    </div>
                    <h4>Website development</h4>
                    <p>From web applications to mobile apps, we create bespoke solutions that drive business outcomes..</p>

                </div>
                <div className="cards">
                    <div className="card-animation">
                        <Lottie animationData={Ghost} />
                    </div>
                    <h4>Sholving your problem Digitally</h4>
                    <p>We listen to your problem, listen to the market, compare them,
                        and provide you a digitally crafted solution.</p>
                </div>
                <div className="cards">
                    <div className="card-animation">
                        <Lottie animationData={Mobile} />
                    </div>
                    <h4>Friend to Entrepreneurs & Business</h4>
                    <p>We provide digital platform building services for new ideas
                        and help existing businesses reach a wider audience online.</p>
                </div>
            </div>




            <div className="home4" id='clients'>
                <div>
                    <h1>Brands</h1>
                    <article>
                        <div style={{
                            animationDelay: "0.3s",
                        }}>
                            <AiFillGoogleCircle />
                            <p>Google</p>
                        </div>

                        <div style={{
                            animationDelay: "0.5s",
                        }}>
                            <AiFillAmazonCircle />
                            <p>Amazon</p>
                        </div>

                        <div style={{
                            animationDelay: "0.7s",
                        }}>
                            <AiFillYoutube />
                            <p>Youtube</p>
                        </div>

                        <div style={{
                            animationDelay: "0.1s",
                        }}>
                            <AiFillInstagram />
                            <p>Instagram</p>
                        </div>
                    </article>
                </div>




            </div>



        </>
    )
}

export default Home