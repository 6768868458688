import React from 'react';
import Rohit from "../assest/rohit.png";
import Rajiv from "../assest/rajiv.png";
import Bhuwan from "../assest/bhuwan.png";
import Mukesh from "../assest/mukesh.png";




const Team = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slideToShow: 3,
        slidesToScroll: 3
    };
    return (
        <div className="team-section">
            <h1>meeat our team</h1>
            <div className="our-team">

                <>

                    <div className="card-container">
                        <div className="image-container">
                            <img src={Rohit} />
                        </div>
                        <h3>Rohit Raj Dahal</h3>
                        <p>Hy my name is rohit raj dahal i am a softwere developer.
                            I m working in this field since 2019</p>
                        <div className="btn">
                            <button>
                                <a href="https://rohyt.netlify.app/">View more</a>
                            </button>
                        </div>

                    </div>
                    <div className="card-container">
                        <div className="image-container">
                            <img src={Rajiv} />
                        </div>
                        <h3>Rajiv Khadka</h3>
                        <p>Hy my name is Rajiv Khadka i am a softwere developer.
                            I m working in this field since 2019</p>
                        <div className="btn">
                            <button>
                                <a href="https://rajibkhadka.com.np">View more</a>
                            </button>
                        </div>

                    </div><div className="card-container">
                        <div className="image-container">
                            <img src={Bhuwan} />
                        </div>
                        <h3>Bhuwan Kafle</h3>
                        <p>Hy my name is Bhuwan Kafle i am a softwere developer.
                            I m working in this field since 2019</p>
                        <div className="btn">
                            <button>
                                <a href="https://bhuwankafle.com.np">View more</a>
                            </button>
                        </div>


                    </div><div className="card-container">
                        <div className="image-container">
                            <img src={Mukesh} />
                        </div>
                        <h3>Mukesh Khadka</h3>
                        <p>Hy my name is Mukesh Khadka i am a softwere developer.
                            I m working in this field since 2019</p>
                        <div className="btn">
                            <button>
                                <a href="">View more</a>
                            </button>
                        </div>
                    </div>

                </>
            </div>
        </div>
    )
}

export default Team