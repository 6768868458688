import React from 'react'
import { Link } from "react-router-dom";
import Logo from "../assest/logo1.png"
import { HashLink } from "react-router-hash-link";
const Head = () => {
    return (
        <nav>
            <div>
                <img src={Logo} />
            </div>

            <main>
                <HashLink to={"/#home"}>Home</HashLink>
                <HashLink to={"/#about"}>About</HashLink>
                <HashLink to={"/#clients"}>Clients</HashLink>
                <Link to={"/contact"}>Contact</Link>
                <Link to={"/services"}>Services</Link>
                <Link to={"/team"}>Team</Link>
                <Link to={"/sess"}>Session</Link>
                {/* <a href="/team">Team</a>
                <a href="/sess">Session</a>
                <a href="/contact">Contact</a>
                <a href="/services">Services</a> */}

            </main>
        </nav>
    )
}

export default Head