import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from "../assest/5.png";
import img2 from "../assest/6.png";
import img3 from "../assest/7.png";
import img4 from "../assest/8.png";





const Services = () => {
    return (
        <>

            <div className="back">
                <div className='services'>
                    <Carousel
                        infiniteLoop
                        autoPlay
                        showStatus={false}

                        interval={2000}
                        showThumbs={false}
                    >
                        <div>
                            <img src={img1} alt="Item1" />
                            {/* <p className="legend">Fullstack</p> */}

                        </div>
                        <div>
                            <img src={img2} alt="Item2" />
                        </div>
                        <div>
                            <img src={img3} alt="Item3" />
                        </div>

                        <div>
                            <img src={img4} alt="Item4" />
                        </div>
                    </Carousel>
                </div>

            </div>
            <h1>Our Services</h1>
            <p>Youth IT offers a wide range of services tailored to meet the diverse needs of our clients:</p>
            <div className='services-offer'>




                <div className="card-container">
                    <h1>Custom Software Development</h1>
                    <p>From web applications to mobile apps, we create bespoke solutions that drive business outcomes.</p>
                </div>
                <div className="card-container">
                    <h1>Custom Software Development</h1>
                    <p>From web applications to mobile apps, we create bespoke solutions that drive business outcomes.</p>
                </div>
                <div className="card-container">
                    <h1>Custom Software Development</h1>
                    <p>From web applications to mobile apps, we create bespoke solutions that drive business outcomes.</p>
                </div>

            </div>
        </>

    )
}

export default Services